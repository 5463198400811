import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AuthFlow from './components/AuthFlow';
import RoleSelection from './components/RoleSelection';
import FarmRegistration from './components/FarmRegistration/FarmRegistration';
import FarmList from './components/FarmList';
import ProfilePage from './components/ProfilePage'; // Import ProfilePage
import RegisteredUsers from './components/RegisteredUsers';
import VillageMap from './components/VillageMap';
import { AuthProvider, useAuth  } from './components/AuthContext'; // Import the AuthProvider
import { useNavigate } from 'react-router-dom';
import "leaflet/dist/leaflet.css";



function AppContent() {
  const { phoneNumber, setPhoneNumber, user, setUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleUnhandledRejection = (event) => {
      const errorMessage = event.reason?.message || event.reason || '';

      if (typeof errorMessage === 'string' && errorMessage.toLowerCase().includes("timeout")) {
        // Suppress specific timeout errors
        console.warn("Suppressed: Unhandled promise rejection with Timeout");
        event.preventDefault(); // Prevent default logging for timeout errors
      } else {
        // Log other unhandled promise rejections for further debugging
        console.error("Unhandled promise rejection:", event.reason);
      }
    };

    window.addEventListener('unhandledrejection', handleUnhandledRejection);

    return () => {
      window.removeEventListener('unhandledrejection', handleUnhandledRejection);
    };
  }, []);

  useEffect(() => {
    if (phoneNumber) {
      console.log('Phone number is now available:', phoneNumber);
      // Perform actions that depend on phoneNumber
    }
  }, [phoneNumber]);

  const handleSuccessfulAuth = async (userPhoneNumber) => {
    console.log('Authentication successful for phone number:', userPhoneNumber);
    setPhoneNumber(userPhoneNumber);

    const isRegistered = await checkRegistrationStatus(userPhoneNumber);
    if (isRegistered) {
      console.log('User is registered. Redirecting to /list');
      navigate('/village-map');
    } else {
      console.log('User is not registered. Redirecting to /role');
      navigate('/village-map');
    }
  };

  const handleSuccessfulRegistration = () => {
    navigate('/village-map'); // Redirect to FarmList after registration
  };

  const handleRoleSelection = async (role) => {
    console.log('handleRoleSelection called with role:', role);
    console.log('Current phoneNumber:', phoneNumber);

  if (!phoneNumber) {
    console.error('Phone number is not available in handleRoleSelection');
    // Optionally, show an error message to the user or wait until phoneNumber is available
    return;
  }
    
    
    if (role === 'trader') {

      await createProfile(phoneNumber, role);
      navigate('/list');
    } else if (role === 'farmer') {
      await createProfile(phoneNumber, role);
      navigate('/registration');
    }
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<AuthFlow onSuccess={handleSuccessfulAuth} />} />
        <Route path="/role" element={<RoleSelection onSelectRole={handleRoleSelection} />} />
        <Route path="/registration" element={<FarmRegistration onSuccess={handleSuccessfulRegistration} />} />
        <Route path="/list" element={<FarmList />} />
        <Route path="/registered-farmers" element={<RegisteredUsers />}/>
        <Route path="/village-map" element={<VillageMap />} />
        <Route path="/profile/:phoneNumber" element={<ProfilePage />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;

// Function to check registration status by phone number
async function checkRegistrationStatus(phoneNumber) {
  try {
    console.log('Checking registration status for:', phoneNumber);

    const response = await fetch(`/api/users/${phoneNumber}`);
    
    if (!response.ok) {
      console.error('API call failed with status:', response.status);
      return false; // Return false if there's an issue with the response
    }
    
    const data = await response.json();
    console.log('API response data:', data);
    
    // Ensure that the profile check is correctly evaluating the registration status
    if (data && data.id) {
      console.log('User is registered:', data.profile);
      return true;
    } else {
      console.log('User is not registered');
      return false;
    }
  } catch (error) {
    console.error('Error checking registration status:', error);
    return false; // Return false in case of an error
  }
}

async function createProfile(phoneNumber, role) {
  try {
    const response = await fetch(`/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phone_number: phoneNumber, role }),
    });
    if (!response.ok) {
      throw new Error(`Profile creation failed: ${response.statusText}`);
    }
    console.log('Profile created successfully');
  } catch (error) {
    console.error('Error creating profile:', error);
  }
}
