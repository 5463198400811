import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import { FiLogOut, FiMapPin } from 'react-icons/fi';
import { logEvent, analytics, auth } from './firebase';
import { useAuth } from './AuthContext';
import './VillageMap.css';

mapboxgl.accessToken =
  'pk.eyJ1Ijoic3Nzc291cmFiaHNoaW50cmUxOTk4IiwiYSI6ImNsdHZnenlqNzFpbDgya25qMnNicDlmZ3gifQ.PZ0K14P3Sbi3t5W7k4HCeQ';

function SearchBox({ onPlaceSelected }) {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error('Google Maps script with Places library not found.');
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place?.geometry?.location) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        onPlaceSelected({ lat, lng });
      }
    });
  }, [onPlaceSelected]);

  return <input ref={inputRef} type="text" placeholder="Search places..." className="search-box" />;
}

export default function VillageMap() {
  const navigate = useNavigate();
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const { phoneNumber } = useAuth();

  const [filters, setFilters] = useState({
    pink: false,
    yellow: false,
    dark_green: false,
    light_blue: false,
  });

  const flyToLocation = (lng, lat) => {
    if (mapRef.current) {
      mapRef.current.flyTo({ center: [lng, lat], zoom: 14 });
    }
  };

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/ssssourabhshintre1998/cm8q94jq900aa01qz8c51bvht',
      center: [74.83401339152063, 16.86276774734358],
      zoom: 15,
    });

    mapRef.current = map;

    map.on('load', () => {
      map.setPaintProperty('output-classified', 'fill-opacity', 0.7);
      map.setPaintProperty('output-classified', 'fill-color', '#000000');
      map.setPaintProperty('output-classified', 'fill-outline-color', '#ffffff');
    });
    flyToLocation(74.7775090168796, 17.08344551742498);

    

    return () => map.remove();
  }, []);

  const handlePlaceSelected = useCallback(({ lat, lng }) => {
    flyToLocation(lng, lat);

    logEvent(analytics, 'place_searched', {
      latitude: lat,
      longitude: lng,
      user_phone: phoneNumber || 'anonymous',
    });
  }, [phoneNumber]);

  const toggleFilter = useCallback(
    (color) => {
      const newFilters = { ...filters, [color]: !filters[color] };
      setFilters(newFilters);

      if (mapRef.current) {
        const activeClasses = Object.keys(newFilters).filter((key) => newFilters[key]);

        if (activeClasses.length > 0) {
          mapRef.current.setFilter('output-classified', [
            'match',
            ['get', 'visual_class'],
            ...activeClasses.flatMap((cls) => [cls, true]),
            false,
          ]);

          mapRef.current.setPaintProperty('output-classified', 'fill-color', [
            'match',
            ['get', 'visual_class'],
            'pink', '#FF0000',
            'yellow', '#f7e03c',
            'dark_green', '#800080',
            'light_blue', '#00bfff',
            '#000000',
          ]);
        } else {
          mapRef.current.setFilter('output-classified', null);
          mapRef.current.setPaintProperty('output-classified', 'fill-color', '#000000');
        }

        logEvent(analytics, 'filter_toggled', {
          filter_color: color,
          active: newFilters[color],
          user_phone: phoneNumber || 'anonymous',
        });
      }
    },
    [filters, phoneNumber]
  );

  const handleLocateMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        flyToLocation(longitude, latitude);
      },
      () => alert('Location access denied or unavailable.')
    );
  };

  return (
    <div className="container">
      <div className="top-controls">
        <SearchBox onPlaceSelected={handlePlaceSelected} />
        <div className="logout-button" onClick={() => navigate('/')}>
          <FiLogOut size={20} style={{ marginRight: '6px' }} /> Logout
        </div>
      </div>

<div className="options-section">
  <strong>🍇Grapes - Harvest Dates (2025)</strong>
  <p style={{ fontSize: '12px', color: '#3b82f6', fontWeight: 'bold', margin: '12px 0 6px' }}>
    Choose from the options below
  </p>
  <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', gap: '6px' }}>
    {[
      ['pink', '#FF0000', 'Before 9th March'],
      ['yellow', '#f7e03c', '9th - 21st March'],
      ['dark_green', '#800080', '22nd March - 2nd April'],
      ['light_blue', '#00bfff', '3rd - 14th April'],
    ].map(([color, code, label]) => (
      <label
        key={color}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <input
          type="checkbox"
          onChange={() => toggleFilter(color)}
          style={{ accentColor: code, marginRight: '8px' }}
        />
        {label}
      </label>
    ))}
  </div>
</div>



      <div className="locate-me-btn" onClick={handleLocateMe}>
        <FiMapPin size={24} />
      </div>

      <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}
